import React from 'react'

import Layout from 'components/Layout'
import Legal from 'components/Layouts/Legal'

import MarkdownContent from 'content/imprint'

const Page = ({ location: { pathname } }) => (
  <Layout seo={{ title: 'Impressum', pathname }}>
    <Legal title="Impressum" lastUpdatedAt="28.09.2020">
      <MarkdownContent />
    </Legal>
  </Layout>
)

export default Page
