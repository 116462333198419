import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`Anbieter`}</h2>
    <p>{`Diese Website wird von der DIE ANTWORT · Büro für Informationstechnik GmbH betrieben.`}</p>
    <h2>{`Kontakt`}</h2>
    <p>{`DIE ANTWORT · Büro für Informationstechnik GmbH`}<br />{`
Obere Weißgerberstraße 4, 1030 Wien`}<br />{`
FN 315384a · HG Wien`}<br /></p>
    <p>{`Tel.: +43 (1) 997 16 20 - 0`}<br />{`
E-Mail: `}<a parentName="p" {...{
        "href": "mailto:team@die-antwort.eu"
      }}>{`team@die-antwort.eu`}</a></p>
    <p><strong parentName="p">{`Firmenbuchnummer`}</strong><br />{`
FN 315384a · HG Wien`}</p>
    <p><strong parentName="p">{`Umsatzsteuer-Identifikationsnummer`}</strong><br />{`
ATU 64397502`}</p>
    <h2>{`Unternehmensgegenstand`}</h2>
    <p>{`IT-Dienstleistungen`}</p>
    <h2>{`Behörde gem. ECG (E-Commerce Gesetz)`}</h2>
    <p>{`Magistratisches Bezirksamt des III. Bezirkes`}</p>
    <h2>{`Anwendbare Rechtsvorschriften`}</h2>
    <p>{`Gewerbeordnung`}</p>
    <h2>{`Rechtliche Hinweise`}</h2>
    <p>{`Die DIE ANTWORT · Büro für Informationstechnik GmbH unternimmt eine ständige Überprüfung und Aktualisierung der Informationen auf ihrer Webseite. Es können sich dennoch Daten in der Zwischenzeit verändert haben. Daher kann eine Haftung oder Garantie für die Aktualität, Richtigkeit und Vollständigkeit der zur Verfügung gestellten Informationen nicht gewährleistet werden.`}</p>
    <p>{`Die DIE ANTWORT · Büro für Informationstechnik GmbH hat das Recht, Änderungen oder Ergänzungen der angeführten Informationen durchzuführen.`}</p>
    <p>{`Die Inhalte und der Aufbau der Internetseite der DIE ANTWORT · Büro für Informationstechnik GmbH sind urheberrechtlich geschützt.`}</p>
    <p>{`© DIE ANTWORT · Büro für Informationstechnik GmbH`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      